import { Action, createReducer, on } from '@ngrx/store';
import * as SurveyPageActions from '../actions/survey.actions';

export const surveyFeatureKey = 'survey';

export interface State {
  measureName: string | null,
  measureInstructions: string | null,
  id: number | null
}

export const initialState: State = {
  measureName: null,
  measureInstructions: null,
  id: null
};

export const surveyReducer = createReducer(
  initialState,

  on(SurveyPageActions.setSurveyName, (state, action) => {
    return {
      ...state,
      measureName: action.measureName
    }
  }),
  on(SurveyPageActions.saveSurveySuccess, (state, action) => {
        return {
        ...state,
        id: action.id,
      };
    }
  )
  
);

export function reducer(state: State | undefined, action: Action) {
  return surveyReducer(state, action);
}