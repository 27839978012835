import { createAction, props } from '@ngrx/store';
import { UserMeasure } from '../../../core/interfaces/userMeasure.model';

export const setSurveyName = createAction(
  '[Survey] Set Survey Name',
  props<{ measureName: string }>()
)

/*************************************************************/
/********** SAVE SURVEYS                      ****************/
/*************************************************************/

export const saveSurvey = createAction(
  '[Survey Component] Save Completed Survey',
  props<{ userMeasure: UserMeasure}>()
)

export const saveSurveySuccess = createAction(
  '[Survey Effect] Save Survey Success',
  props<{ id: number }>()
)

export const saveSurveyFailure = createAction(
  '[Survey Effect] Save Survey Failure',
  props<{ error: any}>()
)