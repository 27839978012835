import { createAction, props } from '@ngrx/store';
import { AuthResponse } from 'src/app/core/interfaces/auth-response.model';
import { IHttpErrorResponse } from 'src/app/core/interfaces/IHttpErrorResponse.model';
import { User } from 'src/app/core/interfaces/user.model';

export const login = createAction(
  '[Login Component] Login User',
  props<{ username: string; password: string }>(),
);

export const loginSuccess = createAction(
  '[Auth Affect] Login User Success',
  props<{ authResponse: AuthResponse }>(),
);

export const loginFailure = createAction(
  '[Auth Effect] Login User Failure',
  props<{ error: any }>(),
);

export const logout = createAction('[Auth Links Component] Logout User');

export const browserReload = createAction(
  '[Core Component] Browser Reload',
  props<{ authResponse: AuthResponse }>(),
);

//API ACTIONS
export const getAuthStatusSuccess = createAction(
  '[Auth/API] Get Auth Status Success',
  (user: User | null) => ({ user }),
);
