import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParamsOptions } from '@angular/common/http';
import { BehaviorSubject, Observable, from, of, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Storage } from '@capacitor/storage';
import { UserService } from './user.service';
import { AuthResponse } from 'src/app/core/interfaces/auth-response.model';
import { Router } from '@angular/router';
import { AuthCode } from 'src/app/core/interfaces/auth-code.model';
import { ResetPassword } from '../../core/interfaces/resetPassword.model';
import { VerifyEmail } from '../../core/interfaces/verifyEmail.model';
import { ResendEmailVerification } from '../../core/interfaces/resendEmailVerify.model'
import { EvexiaUrlService } from './evexiaUrl.service'

const REFRESH_TOKEN_KEY = 'refresh-token';
const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
const USER = 'user';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  token = '';
  expiresIn = 0;
  userId = null;
  jwtHelper = new JwtHelperService();
  private authState: BehaviorSubject<boolean> = new BehaviorSubject(null);
  static TOKEN_KEY = 'auth-user';
  redirectUrl: string;
  evexiaURLStorage: string = '';

  constructor(private httpClient: HttpClient, private router: Router, private UserSvc: UserService, private evexiaUrlSvc: EvexiaUrlService) {
    this.loadToken();       
  }

  async getEvexiaURLFromStorage() {
    const evexiaURL = await Storage.get({ key: 'EvexiaURL' });
    this.evexiaURLStorage = evexiaURL.value;
  }

  async loadToken() {
    const storedUser = await Storage.get({ key: USER });

    const jsonUser: AuthResponse = JSON.parse(storedUser.value);

    //console.log(jsonUser);

    if (jsonUser !== null && jsonUser.token !== null) {

      this.token = jsonUser.token;
      const decoded: any = this.jwtHelper.decodeToken(this.token);


      //console.log('Id: ', decoded.id);
      this.userId = decoded.id;

      this.authState.next(true);
      return jsonUser;
    } else {
      this.authState.next(false);
      return null;
    }
  }

  login(username: string, password: string): Observable<AuthResponse> {
    const loginObservable = this.httpClient.post<AuthResponse>(`${this.evexiaUrlSvc.evexiaURL}/api/auth/login`, JSON.stringify({username, password}), { headers: headers });
    
    loginObservable.subscribe(async (authResponse: AuthResponse) => {
      await Storage.set({key: AuthService.TOKEN_KEY, value: authResponse.token});
      this.token = authResponse.token;
    });
    
    return loginObservable;
  }

  // login(username: string, password: string): Observable<AuthResponse> {
  //   //console.log("Logging In");
  // eslint-disable-next-line max-len
  //   return this.httpClient.post<AuthResponse>(`${this.evexiaUrlSvc.evexiaURL}/api/auth/login`, JSON.stringify({username, password}), {headers: headers}).pipe(
  //     switchMap((data: AuthResponse) => {
  //       //console.log(data);
  //       this.token = data.token;
  //       this.expires_In = data.expiresin;
  //       const decoded: any = this.jwtHelper.decodeToken(this.token);
  //       this.userId = decoded.id;
  //       const storedtokens = Promise.all([
  //         Storage.set({key: TOKEN_KEY, value: this.token}),
  //         Storage.set({key: REFRESH_TOKEN_KEY, value: this.token}),
  //         Storage.set({key: "EXPIRES_IN", value: this.expires_In.toString()})
  //       ]);
  //       return from(storedtokens);
  //       //this.setStorage(res);
  //     }),
  //     tap(_ => {
  //       this.authState.next(true);
  //     }),
  //     catchError(this.handleError<any>('Authenticating User', '[]'))
  //   )
  // }

  logout(): Observable<unknown> {
    return this.httpClient.post(`${this.evexiaUrlSvc.evexiaURL}/api/auth/logout/`, {headers}).pipe(
      tap(async response => {
        console.log("Response from Server: ", response);
        this.authState.next(false);
        this.userId = null;
        this.token = '';
        console.log('Removing ACCESS_TOKEN');
        await Storage.remove({ key: 'ACCESS_TOKEN' });
        console.log('Expires In');
        await Storage.remove({ key: 'EXPIRES_IN' });
        console.log('Refresh Token Key');
        await Storage.remove({ key: 'REFRESH_TOKEN_KEY' });
        console.log('Token Key');
        await Storage.remove({ key: 'TOKEN_KEY' });
        console.log('Removing User');
        await Storage.remove({ key: 'user' });
        console.log('Removing userDetail');
        await Storage.remove({ key: 'userDetail' });
        console.log('Removing Age');
        await Storage.remove({ key: 'age' });
        console.log('Removing auth-user');
        await Storage.remove({ key: 'auth-user' });
        await Storage.remove({ key: 'greatWorkModal'});
        await Storage.remove({ key: 'firstLogin'});
        await Storage.remove({ key: 'EvexiaUrl'});
        console.log('Routing to Login Page');
        this.router.navigate([''], { replaceUrl: true, queryParams: {loggedOut: true} });
      })
    );

  }

  async isAuthenticated(): Promise<boolean> {
    const token = await Storage.get({key: 'ACCESS_TOKEN'});
    const tokenJSON = JSON.parse(token.value).toString();
   // return !this.jwtHelper.isTokenExpired(tokenJSON);
    if (tokenJSON != null){
      return true;
    } else {
      return false;
    }
  }

  isLoggedIn() {
    // this.authState.asObservable().subscribe((data) => {
    //   //console.log(data);
    // })
    ////console.log("AuthState" + this.authState.asObservable())
    return this.authState.asObservable().pipe(
      tap((data) => {
        //console.log("Auth State");
        //console.log(data);
      })
    );
  }



  getSecretTest() {
    return this.httpClient.get(`${this.evexiaUrlSvc.evexiaURL}/users/secret`);
  }

 async getAuthToken() {
    if (this.token == undefined || this.token == ''){
      let response: AuthResponse = await this.loadToken();
      if (response != null) {
        this.token = response.token;
        return this.token;
      } else {
        return '';
      }

    } else {
      return this.token;
    }

  }

  //refresh logic
  isRefreshTokenValid() {
    return from(Storage.get({ key: REFRESH_TOKEN_KEY })).pipe(
      map(token => {
        if (!token.value) {
          return false;
        } else {
          const decoded: any = this.jwtHelper.decodeToken(token.value);
          const now = new Date().getTime();
          return now < decoded.exp * 1000;
        }
      })
    );
  }

  getNewAccessToken() {
    return from(Storage.get({ key: REFRESH_TOKEN_KEY })).pipe(
      switchMap(token => {
        if (token) {
          const httpOptions = {
            headers: new HttpHeaders({
              // eslint-disable-next-line @typescript-eslint/naming-convention
              Authorization: `Bearer ${token.value}`
            })
          };
          return this.httpClient.get(`${this.evexiaUrlSvc.evexiaURL}/auth/refresh`, httpOptions);
        } else {
          of(null);
        }
      })
    );
  }

  saveAccessToken(token) {
    this.token = token;
    return from(Storage.set({ key: AuthService.TOKEN_KEY, value: token }));
  }

  getMyProfile() {
    return this.httpClient.get(`${this.evexiaUrlSvc.evexiaURL}/users/${this.userId}`);
  }

  updateProfile(data) {
    return this.httpClient.put(`${this.evexiaUrlSvc.evexiaURL}/users/${this.userId}`, data);
  }

  sendAuthCode(id) {
    return this.httpClient.post(`${this.evexiaUrlSvc.evexiaURL}/api/auth/codes/${id}/send`, id);
  }

  generateEnrollmentAuthCode(phone) {
    const p = {
      phone
    };
    return this.httpClient.post<AuthCode>(`${this.evexiaUrlSvc.evexiaURL}/api/auth/code/enrollment`, p, {headers});
  }

  verifyAuthCode(id, typeId, authCode) {
    const auth = {
      Id: id,
      TypeId: typeId,
      AuthCode: +authCode
    };

    return this.httpClient.post(`${this.evexiaUrlSvc.evexiaURL}/api/auth/codes/${id}`, auth, {headers}).pipe(
      tap(response => {
        console.log("Response from Server");
        console.log(response);
      })
    );
  }

  resetPassword(resetPasswordRequest: ResetPassword) {
    return this.httpClient.put(`${this.evexiaUrlSvc.evexiaURL}/api/auth/password/reset`, resetPasswordRequest, {headers}).pipe(
      tap(response => {
        console.log('response from the server');
        console.log(response);
      })
    )
  }

  resendEmailVerification(resend: ResendEmailVerification)   {

    return this.httpClient.post(`${this.evexiaUrlSvc.evexiaURL}/api/auth/email/verification/resend`, resend, {headers}).pipe(
      tap(response => {
       // console.log(response);
      })
    )
  }

  verifyEmail(verifyEmail: VerifyEmail) {
    return this.httpClient.put(`${this.evexiaUrlSvc.evexiaURL}/api/auth/email/verify`, verifyEmail, {headers}).pipe(
      tap(response => {
        console.log('EmailVerification');
        console.log(response);
      })
    )
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      //console.log(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }
}
