import {
  ActionReducer,
  ActionReducerMap,
  MetaReducer
} from '@ngrx/store';
import { environment } from '../../../environments/environment';
import * as fromAuth from './reducers/auth.reducer';
import * as fromMeasure from './reducers/measure.reducer';
import * as fromSurvey from './reducers/survey.reducer';
import * as fromRouter from '@ngrx/router-store';
import * as fromQuestionnaire from './reducers/question.reducer';
import * as fromMeasureList from './reducers/measure-list.reducer';

export interface AppState {
  [fromAuth.authFeatureKey]: fromAuth.State;
  [fromMeasure.measureFeatureKey]: fromMeasure.State;
  [fromSurvey.surveyFeatureKey]: fromSurvey.State;
  [fromQuestionnaire.questionFeatureKey]: fromQuestionnaire.State;
  [fromMeasureList.measureListFeatureKey]: fromMeasureList.State;
  router: fromRouter.RouterReducerState;
  authUser: fromAuth.State;
}

export const reducers: ActionReducerMap<AppState> = {
  [fromAuth.authFeatureKey]: fromAuth.reducer,
  [fromMeasure.measureFeatureKey]: fromMeasure.reducer,
  [fromSurvey.surveyFeatureKey]: fromSurvey.reducer,
  [fromQuestionnaire.questionFeatureKey]: fromQuestionnaire.reducer,
  [fromMeasureList.measureListFeatureKey]: fromMeasureList.reducer,
  router: fromRouter.routerReducer,
  authUser: fromAuth.reducer,
};

export const metaReducers: MetaReducer<AppState>[] = !environment.production ? [] : [];

export function debug(reducer: ActionReducer<any>): ActionReducer<any> {
  return function (state, action) {
    //console.log('state', state);
    //console.log('action', action);

    return reducer(state, action);
  }
}
