import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { guid } from 'guid-factory';
import { tap } from 'rxjs/operators';
import { EthnicGroup, Gender, Race, Sex, SupportType, BaseUrl } from 'src/app/core/interfaces/app.model';
import { environment } from 'src/environments/environment';
import { EvexiaUrlService } from './evexiaUrl.service'

@Injectable({
  providedIn: 'root'
})
export class AppService {


  constructor(private httpClient: HttpClient, private evexiaUrlSvc: EvexiaUrlService) {

  }

  getBaseUrlEvexia(evexiaGuid) {
    return this.httpClient.get<BaseUrl[]>(`${environment.apiUrl}/api/users/${evexiaGuid}/baseurlevexia`).pipe(
      tap(
        (data) => {
          //console.log(data);
        }
      )
    )
  }

  getSexList() {
    return this.httpClient.get<Sex[]>(`${this.evexiaUrlSvc.evexiaURL}/api/users/sexes`).pipe(
      tap(
        (data) => {
          //console.log(data);
        }
      )
    )
  }

  getRaceList() {
    return this.httpClient.get<Race[]>(`${this.evexiaUrlSvc.evexiaURL}/api/users/races`).pipe(
      tap(
        (data) => {
          //console.log(data);
        }
      )
    )
  }

  getGenderList() {
    return this.httpClient.get<Gender[]>(`${this.evexiaUrlSvc.evexiaURL}/api/users/genders`).pipe(
      tap(
        (data) => {
          //console.log(data);
        }
      )
    )
  }

  getEthnicGroupList() {
    return this.httpClient.get<EthnicGroup[]>(`${this.evexiaUrlSvc.evexiaURL}/api/users/ethnic-groups`).pipe(
      tap(
        (data) => {
          //console.log(data);
        }
      )
    )
  }

  getSupportTypeList() {
    return this.httpClient.get<SupportType[]>(`${this.evexiaUrlSvc.evexiaURL}/api/support/inquiries/types`).pipe(
      tap(
        (data) => {
          //console.log(data);
        }
      )
    )
  }
}
