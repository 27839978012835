import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
} from '@angular/common/http';
import { from, Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  token: string;
  //isLocked = true;
  constructor(
    private auth: AuthService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return from(this.auth.getAuthToken())
    .pipe(
      switchMap(token => {
        const headers = request.headers.set('Authorization', `Bearer ${ token }`).append('Content-Type', 'application/json');
        const requestClone = request.clone({
          headers
        });
        return next.handle(requestClone);
      })
    );
  }

}
