import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Email, User, DeviceToken } from 'src/app/core/interfaces/user.model';
import Profile from 'src/app/core/interfaces/profile.model';
import { JwtHelperService } from '@auth0/angular-jwt';
import { tap } from 'rxjs/operators';
import NotificationPreferences from 'src/app/core/interfaces/notificationPreferences.model';
import { BehaviorSubject } from 'rxjs';
import { AuthResponse } from 'src/app/core/interfaces/auth-response.model';
import EmailVerification from 'src/app/core/interfaces//verifyEmail.model';
import { EvexiaUrlService } from './evexiaUrl.service'

@Injectable({
  providedIn: 'root'
})
export class UserService {

  authResponse: BehaviorSubject<AuthResponse> = new BehaviorSubject(null);

  constructor(private httpClient: HttpClient, private evexiaUrlSvc: EvexiaUrlService) { }

  getUser(id: number) {
    return this.httpClient.get<Profile>(`${this.evexiaUrlSvc.evexiaURL}/api/users/${id}`);
  }

  verifyEmailUniqueness(email: Email){
    return this.httpClient.post<boolean>(`${this.evexiaUrlSvc.evexiaURL}/api/users/email/validate`, email)
  }

  saveUser(user: Profile) {
    return this.httpClient.put<Profile>(`${this.evexiaUrlSvc.evexiaURL}/api/user/profile/update`, user);
  }

  savePushToken(token: DeviceToken) {
    return this.httpClient.put(`${this.evexiaUrlSvc.evexiaURL}/api/user/device-token`, token).pipe(
      tap((x) => {
        console.log(x);
      })
    );
  }

  resetPassword(body) {
    //console.log(body);
    
    return this.httpClient.put(`${this.evexiaUrlSvc.evexiaURL}/api/user/password`, body).pipe(
      tap((x) => {
        console.log(x);
      })
    );
  }

  getNoficationPreferences() {
    return this.httpClient.get<NotificationPreferences>(`${this.evexiaUrlSvc.evexiaURL}/api/user/notifications/preferences`).pipe(
      tap((x) => {
        console.log(x);
      })
    )
  }


  setNotificationPreferences(body) {
    console.log(body);
    return this.httpClient.put(`${this.evexiaUrlSvc.evexiaURL}/api/user/notifications/preferences`, body).pipe(
      tap((x) => {
        console.log(x);
      })
    )
  }

  getEmailVerificationRequest() {
    return this.httpClient.get<EmailVerification>(`${this.evexiaUrlSvc.evexiaURL}/api/user/verifications/email`).pipe(
      tap((x) => {
        console.log(x);
      })
    )
  }


}
