import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { IonicStorageModule } from '@ionic/storage-angular';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AlertModule } from 'ngx-alerts';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { StoreModule } from '@ngrx/store';
import { EntityDataModule } from '@ngrx/data';
import { entityConfig } from './entity-metadata';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { reducers, metaReducers } from './infrastructure/store';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { JwtInterceptor } from './infrastructure/interceptors/jwt.interceptor';
import { ChartsModule } from '@progress/kendo-angular-charts';
import { GaugesModule } from "@progress/kendo-angular-gauges";
import { SliderModule } from "@progress/kendo-angular-inputs";
import { LabelModule } from "@progress/kendo-angular-label";
import { TransferService } from './infrastructure/services/transfer.service';

import 'hammerjs';
import { ResponseInterceptor } from './infrastructure/interceptors/response.interceptor';
import { StorageService } from './infrastructure/services/storage.service';

@NgModule({

  declarations: [
    AppComponent
  ],

  exports: [
    NgxSpinnerModule,
  ],

  imports: [
    BrowserModule,
    IonicStorageModule.forRoot(),
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ChartsModule,
    InputsModule,
    ButtonsModule,
    GaugesModule,
    SliderModule,
    LabelModule,
    FontAwesomeModule,
    NgxSpinnerModule.forRoot({ type: 'ball-scale-multiple'}),
    AlertModule.forRoot(),
    EntityDataModule.forRoot(entityConfig),
    EffectsModule.forRoot([
     // AuthEffects,
     // AppEffects,
      //RouteEffects,
      //SpinnerEffects,
      //MeasureEffects,
     // AlertEffects,
      //QuestionApiEffects,
     // SurveyEffects
      //ProfileEffects,
      //StartPasswordResetEffects,
      //UserMeasuresEffects
    ]),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production
    }),
    StoreModule.forRoot(
      reducers,
      {
        metaReducers,
        runtimeChecks: {
          strictStateImmutability: true,
          strictActionImmutability: true,
          strictStateSerializability: true,
          strictActionSerializability: true,
          strictActionWithinNgZone: true,
          strictActionTypeUniqueness: true,
        }
      }),
    !environment.production ? StoreDevtoolsModule.instrument() : [], 
    StoreRouterConnectingModule.forRoot(), 
   // StoreModule.forFeature(fromProfile.profileFeatureKey, fromProfile.reducer), 
    //StoreModule.forFeature(fromStartPasswordReset.startPasswordResetFeatureKey, fromStartPasswordReset.reducer), StoreModule.forFeature(fromUserMeasures.userMeasuresFeatureKey, fromUserMeasures.reducer), 
    
  ],

  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    StorageService,
    TransferService
  ],

  bootstrap: [AppComponent],

})

export class AppModule { }
