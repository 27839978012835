import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { ROUTER_CONFIG } from '@ngrx/router-store';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { AuthResponse } from 'src/app/core/interfaces/auth-response.model';
import { AuthService } from '../services/auth.service';
import { UserService } from '../services/user.service';
import { Storage } from '@capacitor/storage';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private authSvc: AuthService,
    private router: Router,
    private userSvc: UserService
    ) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): 
     Promise<boolean>| Observable<boolean> | boolean {

      if (this.authSvc.isAuthenticated) {
       return true;
      } else {
        this.router.navigate(['/login']);
        return false;
      }
  }

  //   return this.apiService.isLoggedIn().pipe(
  //     filter((val) => val !== null),
  //     take(1),
  //     map((authenticated) => {
  //       if (authenticated) {
  //         return true;
  //       } else {
  //         this.router.navigateByUrl('', { replaceUrl: true });
  //       }
  //     }),
  //   );
  // }
}
