import { createAction, props } from '@ngrx/store';
import { Question } from '../../../core/interfaces/question.model';

export const loadQuestions = createAction(
  '[Question] Load Questions',
  props<{ id: number }>()
);

export const loadQuestionsSuccess = createAction(
  '[Question] Load Questions Success',
  props<{ questions: Array<Question> }>()
);

export const loadQuestionsFailure = createAction(
  '[Question] Load Questions Failure',
  props<{ error: any }>()
);

export const selectAnswer = createAction(
  '[Question] Answer Question',
  props<{ question: Question }>()
)
