import { createAction, props } from '@ngrx/store';
import { Question } from 'src/app/core/interfaces/question.model';
import Measure from '../../../core/interfaces/measure.model';


export const get = createAction('[Measure] Get');
export const load = createAction('[Measure] Load');
export const loaded = createAction(
  '[Measure] Loaded',
  props<{ measures: Array<Measure> }>()
)

export const loadMeasures = createAction(
  '[Measure Component] Load Measures'
  );

 export const loadMeasuresSuccess = createAction(
  '[Measure Effect] Load Measures Success',
  props<{ measure: Array<Measure> }>()
);

 export const loadMeasuresFailure = createAction(
  '[Measure Effect] Load Measures Failure',
  props<{ error: any }>()
);

 export const loadMeasureList = createAction(
  '[Measure] Set Measure List',
  props<{ measureList: Array<number> }>()
)
