import { Action, createReducer, on } from '@ngrx/store';
import { AuthResponse } from 'src/app/core/interfaces/auth-response.model';
import { User } from 'src/app/core/interfaces/user.model';
import * as AuthActions from '../actions/auth.actions';

export const authFeatureKey = 'auth';

export interface State {
  gettingStatus: boolean;
  authResponse: AuthResponse;
  user: User,
  error: any;
}

export const initialState: State = {
  gettingStatus: true,
  authResponse: {
      id: null,
      privateToken: null,
      expiresIn: null,
      isAdmin: null,
      publicToken: null,
      token: null,
      name: null
  },
  user: null,
  error: null
};

export const authReducer = createReducer(
  initialState,

  on(AuthActions.loginSuccess, AuthActions.browserReload, (state, action) => {
    
    return { 
      ...state,
      authResponse: action.authResponse,
      error: null
    };

  }),

  on(AuthActions.login, (state, action) => {
    return {
      gettingStatus: true,
      authResponse: null,
      user: null,
      error: null
    };
  }),
  on(AuthActions.getAuthStatusSuccess, (state, action) => {
    return {
      gettingStatus: false,
      user: action.user,
      authResponse: null,
      error: null
    };
  }),
  on(AuthActions.loginFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),
  
  on(AuthActions.logout, (state, action) => {
    return {
      ...state,
      gettingStatus: false,
      user: null,
      authResponse: null,
      error: null,
    };
  })
);

export function reducer(state: State | undefined, action: Action) {
  return authReducer(state, action);
}

export const selectGettingStatus = (state: State) => state.gettingStatus;
export const selectAuthResponse = (state: State) => state.authResponse;
export const selectUser = (state: State) => state.user;
export const selectError = (state: State) => state.error;