import { Action, createReducer, on } from '@ngrx/store';
import Measure from 'src/app/core/interfaces/measure.model';
import * as fromMeasureActions from '../actions/measure.actions';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { state } from '@angular/animations';

export const measureFeatureKey = 'measures';

export interface State {
  loadStatus: 'NOT_LOADED' | 'LOADING' | 'LOADED';
  measures: Measure[],
  error: any
}

export interface MeasureAppstate {
  [measureFeatureKey]: State;
}

export const initialState: State = {
  loadStatus: 'NOT_LOADED',
  measures: [],
  error: null
};

const measureReducer = createReducer (
  initialState,

  on(fromMeasureActions.load, (state) => ({
    ...state,
    loadStatus: 'LOADING'
  })),
  on(fromMeasureActions.loaded, (state, { measures }) => ({
    ...state,
    loadStatus: 'LOADED',
    measures
  })),
  on(fromMeasureActions.loadMeasuresSuccess, (state, action) => 
  {return {
    ...state,
    measures: action.measure
  }}),
  on(fromMeasureActions.loadMeasuresFailure, (state, action) => {
    return {
    ...state,
    error: action.error
  }
  })
);

export function reducer(state: State | undefined, action: Action) {
  return measureReducer(state, action);
}