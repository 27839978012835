import { Component, NgZone, OnInit } from '@angular/core';
import { Storage } from '@capacitor/storage';
import { SplashScreen } from '@capacitor/splash-screen';
import { AppService } from 'src/app/infrastructure/services/app.service';
import { AppConstants } from './app.constants';
import { environment } from 'src/environments/environment';
import { EvexiaUrlService } from './infrastructure/services/evexiaUrl.service'

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  myToken: string;
  baseUrl;
  evexiaUrl: string;

  constructor(
    private appDataSvc: AppService,
    private constants: AppConstants,
    private evexiaUrlSvc: EvexiaUrlService) {
  }

  async ngOnInit() {
    //this.getEvexiaUrl();
    this.splashScreen();
  }

  //No longer being used. Now using https://api.centerfordepression.org which we can control separately from https://www.centerfordepression.org. 
  private async getEvexiaUrl() {
      await this.appDataSvc.getBaseUrlEvexia(this.constants.AppId).toPromise()
      .then((data) => {
        this.baseUrl = data;
        this.evexiaUrl = this.baseUrl.baseUrlEvexia.toString();

        //debugger
        //this.evexiaUrl =  "http://localhost:62708";

        this.evexiaUrlSvc.updateEvexiaURL(this.evexiaUrl);
        console.log("............... app.component.ts - GOT BaseUrlEvexia - this.evexiaUrl = " + this.evexiaUrl);
      });
  }

  private splashScreen(): void {
    setTimeout(() => {
      SplashScreen.hide();
    }, 500)
  }
  ngOnDestroy() {
    //console.log('ngOnDestroy: cleaning up...');
    this.cleanLocalStorage();
  }

  async cleanLocalStorage() {
    await Storage.remove({ key: 'user'});
  }
}
