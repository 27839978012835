import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import Measure from 'src/app/core/interfaces/measure.model';
import { Question } from 'src/app/core/interfaces/question.model';
import * as QuestionActions from '../actions/question.actions';

export const questionFeatureKey = 'question';

export const adapter = createEntityAdapter<Question>();

  export interface State extends EntityState<Question> {
    questions: Question[];
    error: any;
  }

export const initialState: State = adapter.getInitialState({
  questions: [],
  error: null,
});

export const questionsReducer = createReducer(
  initialState,

  on(QuestionActions.loadQuestionsSuccess, (state, action) =>
    adapter.setAll(action.questions, state),
  ),

  on(QuestionActions.loadQuestionsFailure, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),
);

export function reducer(state: State | undefined, action: Action) {
  return questionsReducer(state, action);
}

export const { selectAll, selectEntities } = adapter.getSelectors();
