import { Injectable } from '@angular/core';

@Injectable()
export class TransferService {

  constructor(
  ) { }

  private data;
  private measures;

  setMeasureData(data) {
    this.measures = data;
  }

  getMeasureData() {
    return this.measures;
  }


  setData(data){
    this.data = data;
  }

  getData(){
    let temp = this.data;
    this.clearData();
    return temp;
  }

  clearData(){
    this.data = undefined;
  }

}