import { Action, createReducer, on } from '@ngrx/store';
import * as fromMeasureActions from '../actions/measure.actions';

export const measureListFeatureKey = 'measureList';

export interface State {
  measureList: Array<number>;
}

export const initialState: State = {
  measureList: []
};

export const reducer = createReducer(
  initialState,

  on(fromMeasureActions.loadMeasureList, (state, action) => {
    return {
      ...state,
      measureList: action.measureList
    }
  })
);
