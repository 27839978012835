import { Preferences } from '@capacitor/preferences';

export class StorageService {


    constructor() {
    }


    // async set(key: string, value: any) {
    //         await Preferences.set({key: key, value: value});
    // }

    // async get(Key: string) {

    //     const result = await Preferences.get({key: Key});
    //     let result1 = String(result['value']);
    //         if (result1 != "null") {
    //             return result
    //         }
    //         return null;
    //     } 

    // async remove(key: string) {
    //     await Preferences.remove({key: key});
    // }
}