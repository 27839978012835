import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './infrastructure/guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },

  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'tabs',
    loadChildren: () => import('./pages/tabs/tabs.module').then(m => m.TabsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'profile',
    loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfilePageModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/register/register.page.module').then(m => m.RegisterPageModule)
  },
  // {
  //   path: '',
  //   loadChildren: () => import('./pages/splash/splash.module').then( m => m.SplashPageModule)
  // },
  
  {
    path: 'survey',
    loadChildren: () => import('./pages/surveys/survey/survey.module').then( m => m.SurveyPageModule)
  },
  {
    path: 'error',
    loadChildren: () => import('./pages/error/error.module').then( m => m.ErrorPageModule)
  },{
    path: 'forgot-password',
    loadChildren: () => import('./pages/login/forgot-password/forgot-password.module').then( m => m.ForgotPasswordPageModule)
  },{
    path: 'reset-password/:id', 
    loadChildren: () => import('./pages/login/reset-password/reset-password.module').then( m => m.ResetPasswordPageModule)
  },{
    path: 'reset-password', 
    loadChildren: () => import('./pages/login/reset-password/reset-password.module').then( m => m.ResetPasswordPageModule)
  },
  {
    path: 'resilience',
    loadChildren: () => import('./pages/surveys/resilience/resilience.module').then( m => m.ResiliencePageModule)
  },
  {
    path: 'account',
    loadChildren: () => import('./pages/account/account.module').then( m => m.AccountPageModule)
  },
  {
    path: 'security',
    loadChildren: () => import('./pages/security/security.module').then( m => m.SecurityPageModule)
  },
  {
    path: 'organizations',
    loadChildren: () => import('./pages/organizations/organizations.module').then( m => m.OrganizationsPageModule)
  },
  {
    path: 'notifications',
    loadChildren: () => import('./pages/notifications/notifications.module').then( m => m.NotificationsPageModule)
  },
  {
    path: 'organizationlist',
    loadChildren: () => import('./pages/organizationlist/organizationlist.module').then( m => m.OrganizationlistPageModule)
  },
  {
    path: 'how-score-calculated',
    loadChildren: () => import('./pages/how-score-calculated/how-score-calculated.module').then( m => m.HowScoreCalculatedPageModule)
  },
  {
    path: 'contact',
    loadChildren: () => import('./pages/contact/contact.module').then( m => m.ContactPageModule)
  },
  {
    path: 'report',
    loadChildren: () => import('./pages/report/report.module').then( m => m.ReportPageModule)
  },
  {
    path: 'wellnessDetails',
    loadChildren: () => import('./pages/wellnessDetails/wellnessDetails.module').then( m => m.WellnessDetailsPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})

export class AppRoutingModule { }
