import {Injectable } from '@angular/core';
import { Storage } from '@capacitor/storage';

@Injectable({
  providedIn: 'root',
})
export class EvexiaUrlService {
  public evexiaURL: string = 'https://api.centerfordepression.org';

  updateEvexiaURL(newValue: string) {
    this.evexiaURL = newValue;
    Storage.set({key: 'EvexiaURL', value: this.evexiaURL})
  }
}