// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  //apiUrl: 'http://localhost:62708',
  apiUrl: 'https://www.centerfordepression.org',
  //apiUrl: 'http://10.242.2.2:62708',
  KENDO_UI_LICENSE: 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkxJQyJ9.eyJwcm9kdWN0cyI6W3sidHJpYWwiOmZhbHNlLCJjb2RlIjoiS0VORE9VSVJFQUNUIiwibGljZW5zZUV4cGlyYXRpb25EYXRlIjoxNjk4MDAxMjAwfSx7InRyaWFsIjpmYWxzZSwiY29kZSI6IktFTkRPVUlDT01QTEVURSIsImxpY2Vuc2VFeHBpcmF0aW9uRGF0ZSI6MTY5ODAwMTIwMH0seyJ0cmlhbCI6ZmFsc2UsImNvZGUiOiJLRU5ET1VJVlVFIiwibGljZW5zZUV4cGlyYXRpb25EYXRlIjoxNjk4MDAxMjAwfSx7InRyaWFsIjpmYWxzZSwiY29kZSI6IktFTkRPVUlBTkdVTEFSIiwibGljZW5zZUV4cGlyYXRpb25EYXRlIjoxNjk4MDAxMjAwfV0sImludGVncml0eSI6Imd6cFhtOFNEampHdG9QZ2MxaCtCTlJZdGhTUT0iLCJsaWNlbnNlSG9sZGVyIjoiZGNvcm5pc2hAaXNpODUuY29tIiwiaWF0IjoxNjczNDQ5ODYwLCJhdWQiOiJkY29ybmlzaEBpc2k4NS5jb20iLCJ1c2VySWQiOiI0YzhlYTQ0Yi05YjVkLTQ1ZGUtOGEwZC1mMWExMGRhNTEzNGYifQ.mvzFEqMn9NNMr8CgsEQVSF54qHkTDtj5Sdzy_9_oZ_SpY3o-6G0cE36p5hiEPNjaaEqIiA3HFKRNQH8v2jx2qa7raIrNNfUJ_nkPHgFfjU4IEQt-cuRz9Xx5gHgTn_Z3G7afE2xhql3wjOVqJ34DrWpOyVClU6K-PbTg__dgcM9-sVdn02rtF3oayZ05am1jlkFKvmeductKvZZk4UPhIaqB1tWNMtCqGkw-XoGfkvSNL3r4U7cz_lEfQ9zEbiX-OeuVIchhrqiY18RGsn669ezqTnOYubivKeKI4MKuaCfv9-MwFDLoFQ4sP0dz_qpcH1F3wHrGyWalS22MW_dKRA'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
